<template>
    <div class="box w-24 flex-none flex items-center justify-center">
        <div class="text-center" v-if="row">
            <!-- <div class="text-xl text-font-gray mb-8">Total</div> -->
            <div class="text-2xl">
                {{ row.total.oportunity | numberFormat() }}
            </div>
            <div class="text-font-gray">
                {{ row.total.sales | reduceBigNumbers(2) }}€
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "BallWinTotal",
        props: ['row'],
    }
</script>